import { buttonRole } from '../editor/structures/button';
import { CHAT_APP_DEF_ID } from '../constants';

export const createControllers = (controllerConfigs) =>
  controllerConfigs.map((controller) => ({
    pageReady: () => {
      const buttons = controller.$w(`@${buttonRole}`);
      for (const button of buttons) {
        button.onClick(async () => {
          const chatApi = await controller.wixCodeApi.site.getPublicAPI(
            CHAT_APP_DEF_ID,
          );
          return chatApi.maximize();
        });
      }
    },
    exports: {},
  }))
