export const getButtonStructure = (label = 'Chat') => ({
  type: 'Component',
  skin: 'wixui.skins.Skinless',
  layout: {
    width: 142,
    height: 48,
    x: 10,
    y: 30,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wixui.StylableButton',
  metaData: {
    pageId: 'c1dmp',
  },
  parent: 'comp-k3oaa1vk',
  data: {
    type: 'StylableButton',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
    },
    label,
    svgId: 'a2d129_8434efeb618c4282bde0d0a38703b377.svg',
    id: 'button-chat',
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
    },
    style: {
      properties: {
        '$st-css': ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease;background: value(site_2_3);padding: 8px;padding-right: 16px;padding-left: 16px}.root:hover{border: 1px solid #000000;background: rgba(13,94,186,0.7)}.root:hover::label{color: rgba(255,255,255,0.7)}.root:disabled{background: value(site_1_3)}.root:disabled::label{color: rgba(255,255,255,0.6)}.root:disabled::icon{fill: rgba(255,255,255,0.6)}.root::container{transition: inherit}.root::label{transition: inherit;letter-spacing: 0em;font-family: avenir-lt-w01_35-light1475496,sans-serif,HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;font-size: 16px;color: value(site_1_1);margin: 0px 4px 0px 0px}.root::icon{transition: inherit;fill: value(site_1_1);width: 13px;height: 13px;margin: 0px 0px 0px 4px}.root:hover::icon{fill: rgba(255,255,255,0.7)}',
      },
      propertiesSource: {
        '$st-css': 'value',
      },
    },
    componentClassName: 'wixui.StylableButton',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wixui.skins.Skinless',
  },
  activeModes: {},
  id: 'comp-k3o9qiem',
})

export const buttonRole = 'ChatButton'
